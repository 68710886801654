/* eslint-disable @typescript-eslint/indent */
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import BrFavoriteNumbers from '@components/modules/BrFavoriteNumbers';

import CallingRechargeStore from '@root/stores/CallingRechargeStore';

import BrCard from '@components/common/BrCard';
import BrPriceInfo from '@components/common/BrPriceInfo';
import BrCvvForm from '@components/common/BrCvvForm';
import BrModal from '@components/common/BrModal';
import { useIsDesktop } from '@components/common/MediaQueryMatchers';
import BrDrawer from '@components/common/BrDrawer';
import BrPaymentCardSelect from '@components/common/BrPaymentCardSelect';
import BrButton from '@components/common/BrButton';
import BrExpiredPaymentCardUpdateUi from '@components/common/BrPaymentCardSelect/BrExpiredPaymentCardUpdateUi';
import { TextWithClassNames } from '@components/common/TextDecorators';
import BrSavingsPassSection from '@components/common/BrSavingsPassSection';
import BrModalConfirmationContent from '@components/common/BrModalConfirmationContent';
import BrTooltipWrapper from '@components/common/BrTooltip/BrTooltipWrapper';
import BrSkeleton from '@components/common/BrSkeleton';

import BASE_PATHS from '@root/routes/paths';

import CountryCode from '@root/interfaces/CountryCode';

import CallingPlan from '@root/models/CallingPlans/CallingPlan';
import PaymentCardClass from '@root/models/PaymentCardClass';

import { showBrToast } from '@services/toasts';
import {
  CallingPlanPurchaseParams,
  ComboPlanPurchaseParams,
} from '@services/api/v1/dtcCallingPlans';

import useModal from '@root/hooks/useModal';
import useBrModal from '@root/hooks/useBrModal';
import useDidUpdate from '@root/hooks/useDidUpdate';
import useDidMount from '@root/hooks/useDidMount';

import {
  PaymentCardFundingType,
  PaymentCardFundingTypeMap,
} from '@root/constants/moneyTransfer/paymentCards';

import { capitalizeFirstLetterInEachWord } from '@utils/string';

import BrCallingPlanCard from '../../components/BrCallingPlanCard';

import Store from './Store';

import BottomSectionContainer from './components/BottomSectionContainer';
import DisclaimerWrapper from './components/DisclaimerWrapper';

interface Props {
  selectedPlan: CallingPlan;
  selectedCardHandleId?: string; // last used card handleId, retrieve from the txns history
  userCountryOfOrigin: CountryCode;
  dataTestPrefix?: string;
  onPurchaseButtonClick(
    params: CallingPlanPurchaseParams | ComboPlanPurchaseParams,
    arNewAmount?: number,
  ): void;
  onCancelSubscriptionButtonClick(params: {
    planId: string;
    productId: string;
    txnId: string;
  }): void;
  onPlanEdit?(): void;
}

type ActionBtnType = 'purchase' | 'cancel';
type ActionBtnCfg = {
  text: string;
  type: ActionBtnType;
};

const ACTION_BTN_TYPE: Record<string, ActionBtnType> = {
  PURCHASE: 'purchase',
  CANCEL: 'cancel',
};

const Summary: FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    selectedPlan,
    selectedCardHandleId,
    userCountryOfOrigin = 'US',
    onPurchaseButtonClick,
    onCancelSubscriptionButtonClick,
    onPlanEdit,
  } = props;

  const [isRecipientFormValid, setIsRecipientFormValid] = useState(false);

  const imtuProduct = selectedPlan.comboProducts?.find((comboProduct) => {
    return comboProduct.type === 'imtu';
  });

  const callingRechargeStore = useLocalStore(() => new CallingRechargeStore());

  const store = useLocalStore(() => new Store(imtuProduct?.productId));

  const { t } = useTranslation();
  const isDesktop = useIsDesktop();
  const history = useHistory();

  useDidMount(async () => {
    if (selectedPlan.isSavingsPass) {
      await callingRechargeStore.getCallingInfo();
      if (!callingRechargeStore.isAutoRechargeSet && callingRechargeStore.paymentInfo) {
        store.setSelectedArAmount(
          String(callingRechargeStore.paymentInfo.defaultAutoRechargeAmount),
        );
      }
    }
  });

  const {
    isModalOpen,
    modalHeader,
    modalContent,
    showModal,
    closeModal,
    setModalHeader,
    setModalContent,
  } = useModal();

  const showCvvModal = (card: PaymentCardClass) => {
    setModalHeader(t('Security check'));
    setModalContent(
      <BrCvvForm
        cardIssuerType={card.paySource}
        cardNumber={card.maskedNumber}
        onSubmit={store.setCvv}
      />,
    );
    showModal();
  };

  const actionBtnCfg = useMemo((): ActionBtnCfg | undefined => {
    switch (true) {
      case selectedPlan.isSubscriptionActive:
        return {
          text: t('Cancel Subscription'),
          type: ACTION_BTN_TYPE.CANCEL,
        };
      case selectedPlan.canSubscribe:
        return {
          text: t(`Complete subscription`),
          type: ACTION_BTN_TYPE.PURCHASE,
        };
      case selectedPlan.isActive && selectedPlan.isExtendable:
        return {
          text: t(`Extend plan`),
          type: ACTION_BTN_TYPE.PURCHASE,
        };
      case !selectedPlan.isActive:
        return {
          text: t(`Complete order`),
          type: ACTION_BTN_TYPE.PURCHASE,
        };
      default:
        return undefined;
    }
  }, [
    selectedPlan.isSubscriptionType,
    selectedPlan.isSubscriptionActive,
    selectedPlan.isActive,
    selectedPlan.isExtendable,
    t,
  ]);

  const planSubTypeText = selectedPlan.isSubscriptionType
    ? t('Subscription')
    : t('One time plan');

  const bundledProduct = imtuProduct
    ? {
        title: imtuProduct.title,
        descriptionShort: selectedPlan.comboPromoTagline,
        descriptionLong: imtuProduct.description,
      }
    : undefined;

  const doPurchase = (args: { selectedCard: PaymentCardClass; cvv: string }) => {
    let reqParams: CallingPlanPurchaseParams | ComboPlanPurchaseParams = {
      planId: selectedPlan.productId,
      cardHandleId: args.selectedCard.handleId,
      cvvCode: args.cvv,
      shouldSubscribe: selectedPlan.isSubscriptionType,
    };
    if (
      selectedPlan.comboPromoId &&
      selectedPlan.comboProducts?.[1].productId &&
      store.recipientPhone
    ) {
      reqParams = {
        ...reqParams,
        comboPlanPromoId: selectedPlan.comboPromoId,
        comboPlanImtuOfferId: selectedPlan.comboProducts[1].productId,
        comboPlanRecipientPhoneNumber: store.recipientPhone,
      };
    }
    onPurchaseButtonClick(reqParams, Number(store.selectedArAmount));
  };

  const handleOnCardAdded = (card: PaymentCardClass, cvv?: string) => {
    if (cvv) {
      doPurchase({ selectedCard: card, cvv });
    } else {
      showCvvModal(card);
    }
  };

  const handleOnCardEdited = (card: PaymentCardClass) => {
    showCvvModal(card);
  };

  const {
    selectProps,
    selectedPaymentCard,
    paymentCardReducedDetailsDialogProps,
    isLoading: isLoadingPaymentCards,
    showPaymentCardForm,
  } = BrPaymentCardSelect.useBrPaymentCardSelect({
    handleId: selectedCardHandleId,
    isUsingCardsWithoutBillingAllowed: true,
    userCountryOfOrigin,
    onCardAdded: handleOnCardAdded,
    onCardEdited: handleOnCardEdited,
  });

  const showExpiredPaymentCardModal = (card: PaymentCardClass) => {
    const handleOnAdd = () => {
      showPaymentCardForm();
      selectProps.onCardAdd?.();
      closeModal();
    };

    const handleOnEdit = () => {
      showPaymentCardForm();
      selectProps.onCardEdit?.(card.id);
      closeModal();
    };

    const fundingType = capitalizeFirstLetterInEachWord(
      PaymentCardFundingTypeMap[card.type],
    ) as PaymentCardFundingType;

    setModalHeader(t('Expired payment card'));
    setModalContent(
      <BrExpiredPaymentCardUpdateUi
        maskedNumber={card.maskedNumber}
        onAdd={handleOnAdd}
        onUpdate={handleOnEdit}
        fundingType={fundingType}
      />,
    );
    showModal();
  };

  const nodeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const node = document.querySelector('.br-payment-card-input');
    if (node) {
      // @ts-ignore
      nodeRef.current = node;
    }
  }, [selectProps.isLoading, paymentCardReducedDetailsDialogProps.isLoading]);

  useDidUpdate(() => {
    store.setCvv('');
  }, [selectedPaymentCard]);

  const initPurchase = () => {
    if (selectedPaymentCard) {
      if (selectedPaymentCard.isExpired) {
        showExpiredPaymentCardModal(selectedPaymentCard);
        return undefined;
      }
      if (!store.cvv) {
        showCvvModal(selectedPaymentCard);
      } else {
        doPurchase({
          selectedCard: selectedPaymentCard,
          cvv: store.cvv,
        });
      }
    } else {
      showBrToast.small(t('Select a card'));
    }
    return undefined;
  };

  // cvv is the last step before purchase
  // we trigger purchase once it is filled
  useDidUpdate(() => {
    if (store.cvv) {
      closeModal();
      initPurchase();
    }
  }, [store.cvv]);

  const handleRecipientPhoneInputChange = ({
    phoneNumber,
    isValid,
  }: {
    phoneNumber: string;
    isValid: boolean;
  }) => {
    store.setRecipientPhone(phoneNumber);
    store.setValidationSuccess(isValid);
  };

  const confirmationDialogContent = (onConfirm: () => void, onDecline: () => void) => (
    <BrModalConfirmationContent
      title={t('Cancel subscription')}
      confirmText={t('Proceed')}
      dismissText={t('Dismiss')}
      onConfirm={onConfirm}
      onDecline={onDecline}
    >
      {t('Are you sure you want to cancel your subscription?')}
    </BrModalConfirmationContent>
  );

  const { open: openConfirmationModal, close: closeConfirmationModal } = useBrModal({
    content: confirmationDialogContent,
    hasCloseButton: false,
  });

  const handleCancelSubscriptionButtonClick = async () => {
    const isConfirmed = await openConfirmationModal();
    if (isConfirmed && selectedPlan.txnId) {
      onCancelSubscriptionButtonClick({
        planId: selectedPlan.planId,
        productId: selectedPlan.productId,
        txnId: selectedPlan.txnId,
      });
    }
    closeConfirmationModal();
  };

  const planExpirationMinutesText =
    selectedPlan.isActive && (selectedPlan.expirationDate || selectedPlan.minutesLeft) ? (
      <>
        {selectedPlan.expirationDate && (
          <TextWithClassNames
            text={t('Expires on {bold-text}{{date}}{/bold-text}', {
              date: format(selectedPlan.expirationDate, 'PPP'),
            })}
            markerName="bold-text"
            classNames="text-body/footnote/demibold"
          />
        )}
        {selectedPlan.expirationDate && selectedPlan.minutesLeft && ' | '}
        {selectedPlan.minutesLeft && (
          <>
            {selectedPlan.minutesLeft} {t('minutes remaining')}
          </>
        )}
      </>
    ) : null;

  const cvvDialog = isDesktop ? (
    <BrModal
      header={modalHeader}
      isOpen={isModalOpen}
      onClose={closeModal}
      hasCloseButton
    >
      {modalContent}
    </BrModal>
  ) : (
    <BrDrawer isOpen={isModalOpen} onClose={closeModal} title={String(modalHeader)}>
      {modalContent}
    </BrDrawer>
  );

  const handleArAmountSelect = (newArValue: string) => {
    store.setSelectedArAmount(newArValue);
  };

  const handleRedirectToCallingPlansClick = () => {
    history.push(BASE_PATHS.CALLING_PLANS);
  };

  const arOptions = callingRechargeStore.paymentInfo?.creditCardTiers.map((item) => {
    return {
      label: `${item.price} ${item.currency}`,
      value: item.price,
    };
  });

  const purchaseButtonTooltipText = useMemo(() => {
    switch (true) {
      case store.isRecipientPhoneRequired && !store.recipientPhone:
        return t('Please provide a phone number');
      case store.isRecipientPhoneRequired && !store.isRecipientPhoneValid:
        return t('Please provide a valid phone number');
      default:
        return undefined;
    }
  }, [
    store.isRecipientPhoneRequired,
    store.isRecipientPhoneValid,
    store.recipientPhone,
    t,
  ]);

  const isPurchaseButtonDisabled =
    store.isLoading ||
    isLoadingPaymentCards ||
    callingRechargeStore.isAnythingLoading ||
    (store.isRecipientPhoneRequired && !store.isRecipientPhoneValid);

  return (
    <div className="space-y-middle">
      <BrCallingPlanCard
        key={selectedPlan.planId}
        id={selectedPlan.planId}
        planTypeText={planSubTypeText}
        title={selectedPlan.defaultTitle}
        descriptionShort={selectedPlan.descriptionShort}
        descriptionFull={selectedPlan.descriptionFull}
        destinations={selectedPlan.destinations}
        footnotes={selectedPlan.destinationFootnotes}
        bundledProduct={bundledProduct}
        expirationContent={planExpirationMinutesText}
        functionalAddon={
          selectedPlan.maxFavoriteNumbers && selectedPlan.destinations[0]?.countryCode ? (
            <BrFavoriteNumbers
              planId={selectedPlan.planId}
              maxFavoriteNumbers={selectedPlan.maxFavoriteNumbers}
              countryCode={selectedPlan.destinations[0].countryCode}
            />
          ) : undefined
        }
        isCombo={selectedPlan.isCombo}
        onEditBtnClick={selectedPlan.isActive ? undefined : onPlanEdit}
        recipientFormCfg={
          bundledProduct
            ? {
                onPhoneChange: handleRecipientPhoneInputChange,
                externalValidationFn: store.validateCallingPlanRecipientPhone,
                onValidation: (isFormValid) => {
                  setIsRecipientFormValid(isFormValid);
                },
              }
            : undefined
        }
      />

      {selectedPlan.isSavingsPass &&
        !selectedPlan.isActive &&
        !callingRechargeStore.isAutoRechargeSet &&
        arOptions &&
        callingRechargeStore.paymentInfo?.creditCardTiers && (
          <BrSavingsPassSection
            data={arOptions}
            defaultAmount={callingRechargeStore.paymentInfo.defaultAutoRechargeAmount}
            onAmountSelect={handleArAmountSelect}
          />
        )}

      {!selectedPlan.isSavingsPass && (
        <BrCard>
          <BrPriceInfo totalText={selectedPlan.basePriceText} />
        </BrCard>
      )}

      {actionBtnCfg?.type === ACTION_BTN_TYPE.PURCHASE &&
        !paymentCardReducedDetailsDialogProps.isOpen && (
          <>
            {isLoadingPaymentCards ? (
              <BrSkeleton className="h-[56px] rounded-default" />
            ) : (
              <div className="br-payment-card-input">
                <BrPaymentCardSelect {...selectProps} />
                {!isDesktop && selectedPlan.canSubscribe && (
                  <DisclaimerWrapper className="!mt-default">
                    {t(
                      'By tapping {{btnText}} your card will be charged every month 2 days before your plan expires.',
                      {
                        btnText: t('Complete subscription'),
                      },
                    )}
                  </DisclaimerWrapper>
                )}
              </div>
            )}
          </>
        )}

      {/* Bottom section with action buttons or payment cards form */}
      {actionBtnCfg ? (
        <>
          {actionBtnCfg?.type === ACTION_BTN_TYPE.CANCEL ? (
            <BottomSectionContainer>
              <BrButton
                cmpType="red-text"
                className="w-full"
                onClick={handleCancelSubscriptionButtonClick}
                text={actionBtnCfg.text}
              />
            </BottomSectionContainer>
          ) : (
            <>
              {paymentCardReducedDetailsDialogProps.isOpen ? (
                <>
                  <BrPaymentCardSelect.BrPaymentCardReducedDetailsForm
                    {...paymentCardReducedDetailsDialogProps}
                    submitBtnText={
                      paymentCardReducedDetailsDialogProps.isEdit
                        ? t('Update card & complete {{transactionType}}', {
                            transactionType: selectedPlan.canSubscribe
                              ? t('subscription')
                              : t('order'),
                          })
                        : t('Add card & complete {{transactionType}}', {
                            transactionType: selectedPlan.canSubscribe
                              ? t('subscription')
                              : t('order'),
                          })
                    }
                    dataTestPrefixId=""
                    onClose={paymentCardReducedDetailsDialogProps.onClose}
                    handleId={paymentCardReducedDetailsDialogProps.handleId}
                    key={paymentCardReducedDetailsDialogProps.handleId}
                    onRemove={paymentCardReducedDetailsDialogProps.onRemove}
                    {...(bundledProduct
                      ? {
                          extraValidation: {
                            conditionFunc: () => isRecipientFormValid,
                            errorText: t('Please provide a valid phone number'),
                          },
                        }
                      : {})}
                  />
                  {selectedPlan.canSubscribe && (
                    <DisclaimerWrapper className="!mt-default">
                      {t(
                        'By tapping {{btnText}} your card will be charged every month 2 days before your plan expires.',
                        {
                          btnText: paymentCardReducedDetailsDialogProps.isEdit
                            ? t('Update card & complete {{transactionType}}', {
                                transactionType: t('subscription'),
                              })
                            : t('Add card & complete {{transactionType}}', {
                                transactionType: t('subscription'),
                              }),
                        },
                      )}
                    </DisclaimerWrapper>
                  )}
                </>
              ) : (
                <>
                  {/* To prevent button shifting in case payment card form appears */}
                  {isLoadingPaymentCards ? null : (
                    <BottomSectionContainer>
                      <BrTooltipWrapper
                        cfg={{
                          place: 'top',
                          content: purchaseButtonTooltipText,
                        }}
                      >
                        <BrButton
                          disabled={isPurchaseButtonDisabled}
                          className="w-full"
                          text={actionBtnCfg.text}
                          onClick={initPurchase}
                        />
                      </BrTooltipWrapper>
                      {isDesktop && selectedPlan.canSubscribe && (
                        <DisclaimerWrapper className="mt-default">
                          {t(
                            'By tapping {{btnText}} your card will be charged every month 2 days before your plan expires.',
                            {
                              btnText: t('Complete subscription'),
                            },
                          )}
                        </DisclaimerWrapper>
                      )}
                    </BottomSectionContainer>
                  )}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <BrButton
          cmpType="text"
          className="w-full"
          hasIconLeft
          iconName="plans-outline"
          text={t('View other Calling Plans')}
          onClick={handleRedirectToCallingPlansClick}
        />
      )}

      {selectedPaymentCard?.isExpired ? (
        <BrModal
          header={modalHeader}
          isOpen={isModalOpen}
          onClose={closeModal}
          hasCloseButton
        >
          {modalContent}
        </BrModal>
      ) : (
        cvvDialog
      )}
    </div>
  );
};

export default observer(Summary);
